import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ReplaySubject,
  Subject,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { TrackingobjectGetDto } from 'src/dto/GetDtos/trackingobject-get-dto';
import { TrackingobjectStoreService } from 'src/stores/trackingobject-store.service';

@Injectable({
  providedIn: 'root',
})
export class SelectedObjectService implements OnDestroy {
  private onDestroy$ = new Subject();
  private _selectedObject$ = new ReplaySubject<
    TrackingobjectGetDto | undefined
  >(1);
  selectedObject?: TrackingobjectGetDto;
  selectedObject$;
  constructor(
    private objectStore: TrackingobjectStoreService,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap((params) => {
          let id = params['objectId'];
          if (id != null) {
            return this.objectStore.getSingle(id);
          }
          return of(undefined);
        }),
      )
      .subscribe((item: TrackingobjectGetDto | undefined) => {
        this.setSelectedObject(item);
      });

    this.selectedObject$ = combineLatest([
      this.objectStore.items$,
      this._selectedObject$,
    ]).pipe(
      map(([objects, selectedObject]) =>
        selectedObject
          ? objects.find((object) => object.id == selectedObject.id)
          : selectedObject,
      ),
      distinctUntilChanged(),
      tap((selectedObject) => (this.selectedObject = selectedObject)),
      shareReplay(1),
    );
  }
  setSelectedObject(object: TrackingobjectGetDto | undefined) {
    this._selectedObject$.next(object);
  }
  reload() {
    if (this.selectedObject)
      this.objectStore.refreshItem(this.selectedObject.id);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
